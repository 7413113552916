import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useContractRequest,
  useContractZapSignRequest
} from "../../Hooks/contracts/useContracts.query";
import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Flex,
  Input,
  Space,
  Tag,
  Tooltip
} from "antd";
import {
  formatColorZapSign,
  formatStatusZapSign
} from "../../helpers/formatStatus";
import { formatDate } from "../../helpers/dateHelper";
import {
  validateCep,
  validateCpf,
  validateEmail,
  validatePhone,
  validateRg
} from "../../helpers/validateInputsPersonal";
import {
  CheckCircleOutlined,
  SendOutlined,
  SyncOutlined
} from "@ant-design/icons";
import ClipboardCopy from "../../helpers/copyToClipboard";

import { Typography } from "antd";
import { formatCurrency } from "../../helpers/moneyFormat";
const { Title, Text } = Typography;

const DetailsContract = () => {
  const { id, params } = useParams();

  const { data: contract, isLoading: loadingContract } = useContractRequest(id);

  const {
    data: zpSing,
    isLoading: loadingZapSign,
    isFetching
  } = useContractZapSignRequest(contract?.contract?.token_ZPS);

  const contractArr = contract?.contract;
  const signersArr = zpSing?.signers;

  const handleResendMail = () => {};

  return (
    <>
      <Col md={24}>
        <Card loading={!!isFetching}>
          <p>Contrato ID: {contract?.contract?.contract_number}</p>

          {!contractArr?.status_ZPS ? (
            <>
              <p>O contrato ainda nao foi enviado para o cliente</p>

              <Divider />
              <Descriptions
                bordered
                title="Informações Pessoais"
                items={[
                  {
                    label: "Nome completo",
                    children: contract.name
                  },
                  { label: "Estado civil", children: contract.estado_civil },
                  { label: "Profissão", children: contract.profissao },
                  {
                    label: "Telefone",
                    children: validatePhone(contract.phone)
                      ? contract.phone
                      : "VERIFICAR TELEFONE",
                    contentStyle: {
                      background: validatePhone(contract.phone) ? "" : "#ff4040"
                    },
                    labelStyle: {
                      background: validatePhone(contract.phone) ? "" : "#ff4040"
                    }
                  },
                  {
                    label: "Whatsapp",
                    children: validatePhone(contract.whatsapp)
                      ? contract.whatsapp
                      : "VERIFICAR WHATSAPP",
                    contentStyle: {
                      background: validatePhone(contract.whatsapp)
                        ? ""
                        : "#ff4040"
                    },
                    labelStyle: {
                      background: validatePhone(contract.whatsapp)
                        ? ""
                        : "#ff4040"
                    }
                  },
                  {
                    label: "Email",
                    children: validateEmail(contract.email)
                      ? contract.email
                      : "VERIFICAR EMAIL",
                    contentStyle: {
                      background: validateEmail(contract.email) ? "" : "#ff4040"
                    },
                    labelStyle: {
                      background: validateEmail(contract.email) ? "" : "#ff4040"
                    }
                  }
                ]}
              />
              <br />
              <Descriptions
                bordered
                title="Informações Documentação"
                items={[
                  {
                    label: "RG",
                    children: validateRg(contract.rg)
                      ? contract.rg
                      : "VERIFICAR RG",
                    contentStyle: {
                      background: validateEmail(contract.rg) ? "" : "#ff4040"
                    },
                    labelStyle: {
                      background: validateEmail(contract.rg) ? "" : "#ff4040"
                    }
                  },
                  {
                    label: "CPF",
                    children: validateCpf(contract.cpf)
                      ? contract.cpf
                      : "VERIFICAR CPF",
                    contentStyle: {
                      background: validateEmail(contract.cpf) ? "" : "#ff4040"
                    },
                    labelStyle: {
                      background: validateEmail(contract.cpf) ? "" : "#ff4040"
                    }
                  },
                  {
                    label: "CNH",
                    children: validateCpf(contract.cnh)
                      ? contract.cnh
                      : "VERIFICAR CNH",
                    contentStyle: {
                      background: validateEmail(contract.cnh) ? "" : "#ff4040"
                    },
                    labelStyle: {
                      background: validateEmail(contract.cnh) ? "" : "#ff4040"
                    }
                  }
                ]}
              />
              <br />
              <Descriptions
                bordered
                title="Informações Endereço"
                items={[
                  {
                    label: "Cep",
                    children: validateCep(contract.cep)
                      ? contract.cep
                      : "VERIFICAR CEP",
                    contentStyle: {
                      background: validateCep(contract.cep) ? "" : "#ff4040"
                    },
                    labelStyle: {
                      background: validateCep(contract.cep) ? "" : "#ff4040"
                    }
                  },
                  { label: "Rua", children: contract.endereco },
                  { label: "Cidade", children: contract.cidade },
                  { label: "Bairro", children: contract.bairro },
                  { label: "Estado", children: contract.estado }
                ]}
              />
              <br />
              <Descriptions
                bordered
                title=""
                items={[
                  {
                    label: "Valor Investimento",
                    children:
                      contract.valor <= 0
                        ? "Verifique o Valor"
                        : formatCurrency(contract.valor, "BRL"),
                    contentStyle: {
                      background: contract.valor <= 0 ? "#ff4040" : ""
                    },
                    labelStyle: {
                      background: contract.valor <= 0 ? "#ff4040" : ""
                    }
                  },
                  { label: "Plano", children: contract.plano },
                  { label: "Observaçoes", children: contract.postal_code }
                ]}
              />
            </>
          ) : (
            <>
              <Title level={2}>{zpSing?.name}</Title>
              <Tag
                icon={
                  zpSing?.status === "pending" ? (
                    <SyncOutlined spin />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                color={zpSing?.status === "pending" ? "orange" : "green"}
                style={{ marginBottom: 10, fontSize: 18 }}
              >
                {zpSing?.status === "pending" ? "EM CURSO" : "ASSINADO"}
              </Tag>
              <Flex justify="space-between">
                <Text>
                  Criado em <Text strong>{formatDate(zpSing?.created_at)}</Text>{" "}
                  - por <Text strong>{zpSing?.created_by.email}</Text>
                </Text>

                <Text>
                  Ultima atualização:{" "}
                  <Text strong>{formatDate(zpSing?.last_update_at)}</Text>
                </Text>
              </Flex>

              <Divider />
              <Flex align="" justify="space-between">
                {zpSing?.signed_file && (
                  <Button type="primary">
                    <a href={zpSing?.signed_file} target="_blank">
                      Ver Documento
                    </a>
                  </Button>
                )}
                <Button type="text">
                  <a href={zpSing?.original_file} target="_blank">
                    Documento Original
                  </a>
                </Button>
              </Flex>

              <br />
              <br />

              {signersArr?.map((signer) => (
                <>
                  <Card
                    bordered={false}
                    style={{
                      marginBottom: 20
                    }}
                    styles={{
                      body: {
                        borderRadius: "10px",
                        padding: 0
                      }
                    }}
                  >
                    <Card
                      key={signer.id}
                      bordered={false}
                      style={{
                        borderRadius: "10px",
                        height: "auto",
                        marginBottom: 5
                      }}
                      styles={{
                        body: {
                          borderRadius: "10px",
                          padding: 0
                          // height: "85px"
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: 16
                        }}
                      >
                        <div
                          style={{
                            width: "25px",
                            background: formatColorZapSign(signer.status),
                            // height: "85px",
                            borderRadius: "10px 0 0 10px",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex"
                          }}
                        >
                          {formatStatusZapSign(signer.status)}
                        </div>

                        <div
                          style={{
                            flex: 3,
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px 0 5px 0"
                          }}
                        >
                          <Title
                            level={4}
                            style={{
                              padding: 0,
                              marginTop: 5,
                              marginBottom: 5
                            }}
                          >
                            {signer.name} <br />
                          </Title>
                          {signer.status === "signed" && (
                            <div>
                              Telefone: {signer.phone_number} <br />
                              Email: {signer.email}
                            </div>
                          )}

                          {signer.status !== "signed" && (
                            <div>
                              {signer.times_viewed === 0 && (
                                <Text strong>
                                  <strong>Não visualizou ainda</strong>
                                </Text>
                              )}
                              {signer.times_viewed > 0 && (
                                <>
                                  <Text>
                                    Visualizou o documento
                                    {signer.times_viewed === 1
                                      ? `${signer.times_viewed} vez`
                                      : `${signer.times_viewed} vezes`}
                                    <br />
                                    <Text strong>
                                      Última visualização{" "}
                                      {formatDate(signer?.last_view_at)}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            justifyContent: "flex-end",
                            marginRight: 15,
                            display: "flex"
                          }}
                        >
                          <div
                            style={{
                              // width: "25px",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex"
                            }}
                          >
                            {signer.status === "signed" && (
                              <Text strong style={{ textAlign: "right" }}>
                                Assinou o documento em
                                <br />
                                <Text>{formatDate(signer.signed_at)}</Text>
                              </Text>
                            )}
                            {/* {signer.status !== "signed" && (
                              <Tooltip placement="top" title={"Enviar e-mail"}>
                                <Button type="link" onClick={handleResendMail}>
                                  <SendOutlined style={{ fontSize: 21 }} />
                                </Button>
                              </Tooltip>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </Card>
                    <ClipboardCopy copyText={signer?.sign_url} />
                  </Card>
                  <Divider />
                </>
              ))}
            </>
          )}
        </Card>
      </Col>
    </>
  );
};

export default DetailsContract;
