import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../services/api";

const getWalletBalance = async (value) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/wallet/wallet-balance?operation=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getActiveUserOperatingBalance = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/wallet/operating-balance`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getWalletsToReinvest = async () => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API}/v1/wallet/list-to-reinvestment`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

const postCloneWallet = async (values) => {
  try {
    const data = await api.patch(
      `${process.env.REACT_APP_API}/v1/wallet/clone-wallet-existing?client_id=${values.id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const postDisableWallet = async (values) => {
  const { client_id } = values;
  try {
    const data = await api.patch(
      `${process.env.REACT_APP_API}/v1/wallet/disable-wallet?client_id=${client_id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const postNewWallet = async (values) => {
  try {
    const data = await api.patch(
      `${process.env.REACT_APP_API}/v1/wallet/create-new-wallet`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useWalletAmountBalanceFetchRequest(operation) {
  return useQuery(["walletAmount"], () => getWalletBalance(operation), {
    onSuccess: (data) => {
      return data?.data;
    }
  });
}

export function useWalletExpenseBalanceFetchRequest(operation) {
  return useQuery(["walletExpense"], () => getWalletBalance(operation), {
    onSuccess: (data) => {
      return data?.data;
    }
  });
}

export function useOperatingBalanceRequest() {
  return useQuery(["OperatingBalance"], () => getActiveUserOperatingBalance(), {
    onSuccess: (data) => {
      return data?.data;
    }
  });
}

export function useWalletsReinvestRequest() {
  return useQuery(["list-to-reinvest"], () => getWalletsToReinvest(), {
    onSuccess: (data) => {
      return data?.data;
    }
  });
}

export function useCloneWallet(data) {
  return postCloneWallet(data);
}
export function useDisableWallet(data) {
  return postDisableWallet(data);
}
export function useNewWallet(data) {
  return postNewWallet(data);
}
