import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getPlans = async () => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_API}/v1/plan`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        )}`
      }
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getPlan = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/plan/view?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

const patchPlan = async (values) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_API}/v1/plan/update?id=${values.id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export function usePlansFetchRequest() {
  return useQuery(["plansInvestors"], () => getPlans(), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}

export function usePlanFetchRequest(id) {
  return useQuery(["view-plan", id], async () => await getPlan(id), {
    enabled: !!id,
    retry: 5,
    onSuccess: (data) => {
      return data.data.data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}

export function useUpdatePlan(data) {
  return patchPlan(data);
}
